<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-11-02 17:05:32
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-07-18 11:19:37
-->
<template>
    <div :class="['side-bar', $i18n.locale]">
        <div class="banner">
            <div class="title">
                <img src="@/assets/images/LOGO.png" alt="" />
            </div>
            <div class="triangle"></div>
        </div>
        <div class="side-list" @touchmove.prevent>
            <ul>
                <li v-for="(item, index) in sideList" :key="index" @click="onSideMenu(item)">
                    <img :src="item.img" alt="" class="img" />
                    <p class="text">{{ item.text }}</p>
                </li>
            </ul>
        </div>
 
        <div class="privacy" @click="goToUser('UserSecret')" v-if="$i18n.locale === 'ja-JP'">
            <i>{{ $t('sidebar.read.privacy') }}</i>{{ $t('sidebar.read.reading') }}
        </div>
        <div class="privacy" @click="goToUser('UserSecret')" v-else>
            {{ $t('sidebar.read.reading') }}<i>{{ $t('sidebar.read.privacy') }}</i></div>
      
    </div>
</template>

<script>
import UserAvatar from '@/components/user/Avatar'
import SidebarItem from '@/components/sidebar/Item'
import changeIcon from '@/assets/images/sidebar/hours_line@2x.png'
import contacts from '@/assets/images/sidebar/contacts_line@2x.png'
import global from '@/assets/images/sidebar/a_b@2x.png'
export default {
    data() {
        return {
            components: {
                UserAvatar,
                SidebarItem,
            },
            sideList: [
                {
                    text: this.$t('sidebar.personal'),
                    img: contacts,
                    link: '/personal',
                },
                {
                    text: this.$t('sidebar.cut'),
                    img: global,
                    link: '/switching',
                },
                {
                    text: this.$t('sidebar.changeSort'),
                    img: changeIcon,
                    link: '/productModel',
                },
            ],
            code: '',
        }
    },
    methods: {
        onSideMenu(menu) {
            this.$router.push({
                path: menu.link,
            })
            console.log(menu)
        },
        // 跳转到用户使用/隐私协议
        goToUser(pathName) {
            this.$router.push({
                name: pathName,
            })
            if (this.iphoneResult || this.form.code) {
                window.sessionStorage.setItem('lookUserInfo', JSON.stringify(this.form))
            }
        },
    },
}
</script>

<style lang="less" scoped>
.side-bar {
    width: 100%;
    height: 100%;
    font-size: 14px;
    // background: url(../../assets/images/sidebar/lALPBbCc1dfvirHNBLjNAhA_528_1208.png)
    //   no-repeat center;
    // background-size: 100% 100%;
    background: #eaebff;
    overflow: hidden;

    .banner {
        position: relative;
        width: 100%;
        height: 121px;
        background: url('~@/assets/images/sidebar/side-bg.png') no-repeat center;
        background-size: 100% 100%;
        padding-top: 40px;
        z-index: 1;

        .title {
            font-size: 21px;
            color: #fff;

            img {
                width: 157px;
                height: 20px;
            }
        }

        .triangle {
            position: absolute;
            top: 70%;
            right: 0;
            width: 0;
            height: 0;
            border-top: 24px solid transparent;
            border-right: 152px solid #313251;
            border-bottom: 25px solid transparent;
            z-index: -1;
        }
    }

    .side-list {
        width: 100%;
        height: calc(100% - 161px);
        padding-left: 72px;
        padding-top: 64px;

        ul {
            text-align: left;


            li {
                margin-bottom: 24px;
                display: flex;
                flex-direction: row;

                .text {
                    font-family: PingFangSC-Regular, PingFang SC;

                    font-size: 14px;
                    margin-left: 10px;
                    color: #000;
                }

                .img {

                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .privacy {
        position: absolute;
        bottom: 44px;
        width: 100%;
        text-align: center;
        color: #abacbb;

        i {
            font-style: normal;
            color: #1499ff;
        }
    }

    &.ja-JP {
        .side-list {
            padding-left: 58px;
        }

        .privacy {
            padding: 0 30px 0 30px;
            width: 260px;
            text-align: center;
        }
    }

    &.en-US,
    &.it-IT,
    &.fr-FR,
    &.ru-RU,
    &.es-ES,
    &.pt-PT {
        .side-list {
            padding-left: 45px;
        }
    }
    &.ar-AR{
        .side-list{
            ul{
                margin-right: 34px;
                li .text{
                    margin-right: 10px;
                    margin-left: 0;
                }
            }
        }
    }
}

</style>
