<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-11-03 17:43:57
 * @LastEditors: zhaoqianqian
 * @LastEditTime: 2021-11-04 13:38:13
-->
<template>
    <!-- 08侧边栏的单条数据模板 -->
    <router-link class="side-list-model" :to="link">
        <span :class="`side-list-li ` + img">
            <slot name="haveUnread"></slot>
        </span>
        {{text}}
    </router-link>
</template>

<script type="text/ecmascript-6">
export default {
    props: [
        'img',
        'text',
        'link' // 跳转链接
    ],
    data() {
        return {}
    }
}
</script>

<style scoped lang="less">
.side-list-model {
    display: block;
    text-decoration: none;
    color: #222;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.side-list-li {
    display: inline-block;
    width: 16px;
    height: 23px;
    margin-right: 12px;
    vertical-align: middle;
    margin-top: -2px;
}
.side-send-report {
    background: url(../../assets/images/sidebar/leftbar_SendReport_icon.png) no-repeat center center;
    background-size: 16px 16px;
}
.side-collect-report {
    background: url(../../assets/images/sidebar/leftbar_CheckReport_icon.png) no-repeat center center;
    background-size: 16px 16px;
}
.side-model-management {
    background: url(../../assets/images/sidebar/leftbar_Model_icon.png) no-repeat center center;
    background-size: 16px 16px;
}
.side-problem-feedback {
    background: url(../../assets/images/sidebar/leftbar_customerFeedback_icon.png) no-repeat center center;
    background-size: 16px 16px;
}
.side-help-content {
    background: url(../../assets/images/sidebar/leftbar_PersonalCenter_icon.png) no-repeat center center;
    background-size: 16px 16px;
}
.side-about-our {
    background: url(../../assets/images/sidebar/leftbar_abut_icon.png) no-repeat center center;
    background-size: 16px 16px;
}
</style>
